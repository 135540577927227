
import { SelectModel } from "@/core/models/SelectModel"
import { BranchGroup } from "@/core/models/BranchGroup"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  name: "branch-group-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const branchGroupData = ref<BranchGroup>({} as BranchGroup)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const businessList = ref<SelectModel[]>([])

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "Hesap"),
      name: getRule(RuleTypes.TEXT50, "Ad"),
    })

    const getBranchGroup = async id => {
      const { data } = await ApiService.get("branch-group/" + id)
      branchGroupData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getBranchGroup(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          branchGroupData.value = {} as BranchGroup
        }
      }
    )

    async function addBranchGroup() {
      const data = { ...branchGroupData.value }
      await ApiService.post("branch-group/add", data)
      emit("getlist")
      router.push({ name: "branch-group-list" })
    }
    async function updateBranchGroup() {
      const data = { ...branchGroupData.value, id: props.id }
      await ApiService.post("branch-group/edit", data)
      emit("getlist")
      router.push({ name: "branch-group-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addBranchGroup()
          } else {
            updateBranchGroup()
          }
        }
      })
    }

    onMounted(async () => {
      await getBusinessList()
    })

    async function getBusinessList() {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        branchGroupData.value.businessId = businessList.value[0].id
      }
    }

    return {
      showDrawer,
      newBranchGroup: add,
      activeBranchGroupId: id,
      handleClose: props.closed,
      branchGroupData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      rules,
    }
  },
})
