
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import BranchGroupComponent from "@/components/general/definitions/branch-group/branch-group.vue"
import { BranchGroup } from "@/core/models/BranchGroup"
import { SelectModel } from "@/core/models/SelectModel"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "branch-group-list",
  components: { BranchGroupComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)

    const newBranchGroup = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchGroupList = ref<BranchGroup[]>([])
    const activeBusinessId = ref<string>()

    const activeBranchGroupId = ref<string | undefined>()

    watch(
      () => route.params.branch_group_id,
      newValue => {
        activeBranchGroupId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newBranchGroup.value = true == newValue
      }
    )

    onMounted(async () => {
      activeBranchGroupId.value = route.params.branch_group_id as string
      newBranchGroup.value = props.add
      await getBusinessList()
    })

    async function getBusinessList() {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        await getBranchGroupList(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    async function getBranchGroupList(businessId) {
      if (!businessId) return
      isTableDataLoading.value = true
      const { data } = await ApiService.get("branch-group/business/" + businessId)
      branchGroupList.value = data
      isTableDataLoading.value = false
    }

    async function removeBranchGroup(branchGroupId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("branch-group/remove/" + branchGroupId)
        await getBranchGroupList(activeBusinessId.value)
      }
    }

    function handleBranchGroupDrawerClose() {
      router.push({ name: "branch-group-list" })
    }

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      branchGroupList,
      businessList,
      activeBusinessId,
      Edit,
      removeBranchGroup,
      getBranchGroupList,
      activeBranchGroupId,
      newBranchGroup,
      handleBranchGroupDrawerClose,
      isBusinessSelectLoading,
    }
  },
})
